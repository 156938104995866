import { useEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";

export const useSelectData = () => {
  const { apiUrlCat } = useAppContext(); 

  const [typesTemplates, setTypesTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [typesCodes, setTypesCodes] = useState([]);

    const fetchData = async () => {
      try {
        const typesTemplatesResponse = await fetch(`${apiUrlCat}/types-templates`);
        const categoriesResponse = await fetch(`${apiUrlCat}/categories`);
        const typesCodesResponse = await fetch(`${apiUrlCat}/types-codes`);

        if (typesTemplatesResponse.ok && categoriesResponse.ok && typesCodesResponse.ok) {
          const typesTemplatesData = await typesTemplatesResponse.json();
          const categoriesData = await categoriesResponse.json();
          const typesCodesData = await typesCodesResponse.json();

          setTypesTemplates(typesTemplatesData.typesTemplates.reverse());
          setCategories(categoriesData.categories.reverse());
          setTypesCodes(typesCodesData.typesCodes.reverse());
        } else {
          throw new Error("Erreur lors de la récupération des données");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    useEffect(() => {
      const getOptions = async () => {
        await fetchData();
        setTimeout(getOptions, 1000);
      };
      getOptions(); 
    }, []);

  return {
    typesTemplates,
    categories,
    typesCodes,
  };
};
