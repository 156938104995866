import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectData } from "../redux/features/userSliceWithTokenValidation";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const storedDarkMode = localStorage.getItem("darkMode");
  const [darkMode, setDarkMode] = useState(storedDarkMode === "true");
  const storedUser = useSelector(selectData)
  const [user, setUser] = useState(() => {
    return storedUser ? storedUser : null;
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlImg = process.env.REACT_APP_API_URL_IMG;
  const apiTel = process.env.REACT_APP_API_URL_TELECHARGE;
  const apiUrlCat = process.env.REACT_APP_API_URL_CATEGORIE;
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [tabcat, setTabcat] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);

  const updateUserInfos = (newUserInfo) => {
    setUser(newUserInfo);
  };

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const countries = await response.json();
        const options = countries
          .map((country) => ({
            label: country.name.common,
            value: country.cca2,
            flag: country.flags.svg
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setCountryOptions(options);
      } catch (error) {
        console.error("Erreur lors du chargement des pays :", error);
      }
    };
  
    fetchCountries();
    if (storedUser) {
      setUser(storedUser);
      setIsAuthenticated(true);
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleSearchModal = () => {
    setSearchModalOpen(!searchModalOpen);
  };

  const updateTabcat = (categories) => {
    setTabcat(categories);
  };

  return (
      <AppContext.Provider
        value={{
          darkMode,
          toggleDarkMode,
          user,
          isAuthenticated,
          setUser,
          apiUrlImg,
          apiUrl,
          apiTel,
          apiUrlCat,
          searchModalOpen,
          toggleSearchModal,
          updateUserInfos,
          tabcat,
          storedUser,
          updateTabcat,
          countryOptions
        }}
      >
        {children}
      </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const ctx = useContext(AppContext);
  if (!ctx) throw new Error("No context provided");
  return ctx;
};
