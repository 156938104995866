import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer, persistStore
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import API from "../services/auth-services/AuthService";
import userSlice from "./features/userSlice";
import userSliceWithTokenValidation from "./features/userSliceWithTokenValidation";
import API_TEMPLATE from "../services/templates_services/TemplateService";
import API_DOWNLOAD from "../services/downdload-service/DowndloadService";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
}

const rootReducers = combineReducers({
    auth: userSliceWithTokenValidation,
    user: userSlice,
    [API.reducerPath]: API.reducer,
    [API_TEMPLATE.reducerPath]: API_TEMPLATE.reducer,
    [API_DOWNLOAD.reducerPath]: API_DOWNLOAD.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(API.middleware, API_TEMPLATE.middleware, API_DOWNLOAD.middleware),
})

export const persistor = persistStore(store);