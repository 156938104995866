import React, { useEffect, useState } from 'react';
import TaskCard from './TaskCard';
import MonBouton from '../Utils/design-system/buttons/MonBouton';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { LOGIN_PATH } from '../../Routes/navigation/navigationPaths';
import SkeletonLoader from '../Utils/Skeleton';
import { ProjectsEndpoints } from '../../Routes/api/Endpoints';
import Breadcrumbs from '../Utils/Breadcrumbs';
import RenderWysiwyg from 'render-wysiwyg';
import { BsExclamationCircleFill } from "react-icons/bs";
import { useModal } from '../../context/ModalContext';
import CarouselModal from '../Utils/CarouselModal';
import { selectData } from '../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useFetchProjectsQuery, useGetDataQuery, usePostDataMutation } from '../../services/auth-services/AuthService';
import CardDetailProject from './details_projects/CardDetailProject';
import { IoPeopleOutline } from 'react-icons/io5';
import { BsDatabaseSlash } from "react-icons/bs";
import { MdOutlineAccessTime } from "react-icons/md";
import ClientInfos from './details_projects/ClientInfos';
import { formatDuration } from '../Utils/FormatDate';
import { useAppContext } from '../../context/AppContext';

function DetailProjectContent() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    const { data: tasks } = useGetDataQuery({ endpoint: ProjectsEndpoints.getTasks });
    const { data: projets, isLoading:loadingProject, refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);
    const { slug } = useParams();
    const { apiUrlImg } = useAppContext();
    const tasksDispo = tasks ? tasks?.filter((task) => task?.assigned_to_id === null) : [];
    const project = projets && projets?.find((proj) => proj.slug === slug)
    const task = tasks && tasks.filter((tasked) => tasked?.project_id === Number(project?.id));
    const user = useSelector(selectData)
    const [formError, setFormError] = useState("")
    const {closeModalConfirm, isOpenModalConfirm, openModalConfirm} = useModal()
    const [postData, { isLoading } ] = usePostDataMutation();
    const skeletonTab = [{ id: "skeleton1", widthtext: "100%" }, { id: "skeleton2", widthtext: "50%" }, { id: "skeleton3", widthtext: "35%" }]
    const competences = [{ "name": "Next.js" }, { "name": "React.js" }, { "name": "Node.js" }, { "name": "GraphQl" }];
    const apropos = [{ "icon": <IoPeopleOutline size={14} />, "text": "05 membres" }, { "icon": <BsDatabaseSlash size={14} />, "text": "Ouvert aux enchères" }, { "icon": <MdOutlineAccessTime size={14} />, "text": "Actif récemment" }];
    
    const redirectUser = () => {
        toast('Veuillez vous connecter en tant que bakeliste pour demander à rejoindre le projet!', {
            icon: <BsExclamationCircleFill className='text-blue-400' size={"44px"} />,
            style: {
                border: '1px solid #d3d3d3',
                padding: '16px',
                color: '#333',
                backgroundColor: '#f9f9f9',
            },
        });
        setTimeout(() =>{
            navigate(`${LOGIN_PATH}/${slug}`)
        }, 1000)
    }
    

    const handlePostuler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('statut', user.statut);
        postData({ endpoint: ProjectsEndpoints.postCandidatureJoin, elementID: project?.id, formData })
        .unwrap()
        .then((data) => {
            toast.success(data.message)
          closeModalConfirm()
          refetch();
        }).catch((error) => {
            setFormError(error?.message)
          toast.error(error?.data.message)
          console.log(error);
          
        });
    };
    // console.log(project);
    

    return (
      <>
        <div className="flex items-center flex-col">
          <div
            className="flex flex-col max-xl:pt-36 max-lg:pt-28 w-full relative h-60"
          >
            <div className='w-full h-full relative'>
              <img src={`${apiUrlImg}/${project?.image}`} alt="img" className='w-full h-full object-cover' />
              <div className='absolute bg-black/30 w-full inset-0 h-full'></div>
            </div>
            <Breadcrumbs home={true} position={"absolute bottom-4 left-20"} />
          </div>
          <div className="w-[90%] ps-4 pt-6 justify-between flex flex-wrap">
            <div className="w-2/3 max-lg:w-full">
              <CardDetailProject
                title={project?.titre}
                cardPadding={"!px-8 !py-6"}
                head={"flex justify-between"}
                className={"text-3xl font-inter font-bold pb-1 text-noir"}
                price={project?.budget}
                presentation={true}
                duration={"6"}
                publicationDate={formatDuration(project?.created_at)}
              />
              <div className="border card rounded-2xl shadow mt-6 p-8">
                <div className="flex flex-col gap-2">
                  <div className="border-b pb-1">
                    {loadingProject ? (
                      <SkeletonLoader
                        text={true}
                        widthtext={"50%"}
                        heightText={"0.8rem"}
                      />
                    ) : (
                      <h2 className="text-xl font-inter font-bold text-noir">
                        {"Description du projet"}
                      </h2>
                    )}
                  </div>
                  <div className="text-sm flex flex-col gap-5">
                    {loadingProject ? (
                      skeletonTab.map((elem, i) => (
                        <SkeletonLoader
                          text={true}
                          heightText={"0.8rem"}
                          {...elem}
                          key={i}
                        />
                      ))
                    ) : (
                      <div className="px-1 font-sans tex-md">
                        <RenderWysiwyg content={project?.description} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <CardDetailProject
                title={"Compétences requises"}
                competences={competences}
                className={
                  "text-xl font-inter font-bold border-b pb-1 text-noir"
                }
              />
              <CardDetailProject
                title={"A propos de ce projet"}
                apropos={apropos}
                ID={true}
                id={"38530595"}
                className={
                  "text-xl font-inter font-bold border-b pb-1 text-noir"
                }
              />
            </div>
            <div className="w-1/3 flex flex-col items-center gap-6 pt-4 max-lg:w-full">
              <div className="w-full flex flex-col items-end gap-6">
                {user?.statut === "client" ||
                user?.statut === "coach" ||
                project?.rejoignants_ids?.includes(user?.id) ? (
                  ""
                ) : (
                  <MonBouton
                    children={isLoading ? "En cours..." : "Rejoindre le projet"}
                    couleurFond={"bg-orange"}
                    bordure={
                      "border py-2 max-sm:py-1 max-sm:px-1 max-xl:text-sm max-lg:text-[8px] max-lg:p-1 rounded-full justify-center text-base font-inter text-white gap-2 font-sans font-medium max-sm:text-[8px] max-lg:w-3/4 w-11/12"
                    }
                    action={
                      user?.statut === "bakeliste"
                        ? () => openModalConfirm()
                        : redirectUser
                    }
                    type={"button"}
                  />
                )}
                <ClientInfos
                  clientName={project?.user_name}
                  date={project?.created_at}
                />
              </div>
              <h2 className="text-xl font-inter text-start w-10/12 font-bold text-noir">
                Tâches associées au projet
              </h2>
              <div className="flex flex-col scrollbar-thin scrollbar-thumb-grisrel items-end w-full  max-h-[438px] overflow-y-scroll">
                {loadingProject ? (
                  skeletonTab.map((elem, i) => (
                    <SkeletonLoader {...elem} key={i} cardTask={true} />
                  ))
                ) : task && task.length > 0 ? (
                  task.map((elem, i) => (
                    <TaskCard
                      titre={elem.titre}
                      finished_at={elem.finished_at}
                      id={elem.id}
                      key={i}
                      budget={elem?.budget_tache}
                      started_at={elem.started_at}
                      width={`w-11/12 bg-white mb-4 pb-0 ${
                        task.length < 2 ? "max-md:!w-full" : "max-md:!w-[40%]"
                      }`}
                    />
                  ))
                ) : (
                  <h1 className="text-md font-sans font-semibold text-center w-full">
                    Aucune tache pour le moment
                  </h1>
                )}
              </div>
            </div>
          </div>
        </div>
        {isOpenModalConfirm && (
          <CarouselModal
            onClose={closeModalConfirm}
            modalConfirm={true}
            actionText={"rejoindre ce projet"}
            validation={true}
            title={"Confirmation"}
            actionConfirm={handlePostuler}
            loader={isLoading}
            actionAnnuler={closeModalConfirm}
          />
        )}
      </>
    );
}

export default DetailProjectContent;