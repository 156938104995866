// Endpoints pour les nouvelles types de selects 
export const TemplatesEnpoints = {
  postCategories: "addCategories",
  postTypeTemplate: "templates",
  postTypeCode: "addTypesCode",
  postTemplateWorker: "templates",
  postTypeTelechagerTemplate: "type-telechargement",
  getTypeTemplate: "types-templates",
  getCategory: "categories",
  getTypesCode: "types-codes",
  getCategorieTemplate: "categories-templates",
};

export const AuthentificationEndpoints = {
  updatePassword: "reinitialiser",
  addResetLinkEmail: "demande",
  resetPassword: "resetPassword",
  getAccessToken: "getAccessToken",
  refreshToken: "refresh",
  getUserData: "getUserData",
  addGoogleUser: "addGoogleUser",
  register: "register",
  login: "login",
  logout: "logout",
  postUserLogin: "login",
  updateUserData: "modifier-infos/",
};

export const ProjectsEndpoints = {
  postPorteurProjet: "porteur-projet/",
  postProjet: "projets",
  getProjects: "projets",
  getOneProjects: "getProjects?id=",
  getTemplates: "templates/{type?}",
  postForStore: "store",
  updateTask: "modifier-tache/",
  getTasks: "taches",
  getTaskById: "taches/",
  postCandidature: "postuler/",
  getPostulant: "postulants/",
  postCandidatureJoin: "rejoindre/",
  updateAssignation: "assigner/",
  updateParticipants: "participer/",
  getProjectMembers: "rejoignants/",
  getParticipants: "participants/",
  postCommentTask: "commentaires-tache/",
  getComments: "commentaires-tache/",
  deleteMicroTask: "commentaires/",
  updateComment: "commentaires/",
  postLivraisonComment: "commentaires-livraison/",
  getCommentsLivraisons: "commentaires-livraison/",
  postMicroTask: "micro-taches/",
  getMicroTask: "micro-taches/",
  updateMicroTask: "terminer/",
  ajoutLivraison: "livraisons/",
  getLivraisons: "livraisons/",
  deleteDelivary: "livraisons/",
  getLivraisonById: "livraison/",
  validerLivraison: "valider-livraison/",
  rejeterLivraison: "rejeter-livraison/",
  updateDelivery: "modifier/",
  validerTache: "valider-tache/",
  rejeterTache: "rejeter-tache/",
  updateTaskDate: "dates/",
  getNotifications: "notifications",
};