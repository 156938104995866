import React, { useState } from "react";
import { BsFillCameraFill } from "react-icons/bs";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useAppContext } from "../../context/AppContext";
import { BiEdit, BiError, BiImage} from "react-icons/bi";
import imageProfile from "../../assets/face8.jpg.png";
import { AuthentificationEndpoints } from "../../Routes/api/Endpoints";
import { profileInputs } from "../../components/Utils/profileInputsData";
import { usePostDataMutation } from "../../services/auth-services/AuthService";
import { selectData, setUserData } from "../../redux/features/userSliceWithTokenValidation";
import { useSelector, useDispatch } from "react-redux";
import MonInput from "../../components/Utils/design-system/inputs/MonInput";

const ParameterClientPage = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [postData, { isLoading }] = usePostDataMutation();
  const user = useSelector(selectData);
  const dispatch = useDispatch()

  const { apiUrlImg, countryOptions } = useAppContext();


  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setErrorMessage("");
      };
      reader.readAsDataURL(file);
    }
  };

  const [formState, setFormState] = useState({
    name: "",
    number: "",
    profile: "",
    password: "",
    password_confirmation: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  const formData = new FormData();

  Object.entries(formState).forEach(([key, value]) => {
    if (value) formData.append(key, value);
  });
  if(selectedImage) formData.append("profile", selectedImage);
  if ([...formData.entries()].length === 0) {
    setErrorMessage("Veuillez modifier au moins un champ avant de soumettre.");
    return;
  }
  if (formState.password && !formState.password_confirmation) {
    setErrorMessage("Veuillez confirmer le mot de passe.");
    return;
  }

  postData({ endpoint: AuthentificationEndpoints.updateUserData, elementID: user.id, formData })
  .unwrap()
  .then((data) => {
    toast.success(data.message);
    setFormState({
      name: "",
      number: "",
      password: "",
      password_confirmation: "",
    });
    setSelectedImage("");
    setImagePreview("");
    dispatch(setUserData(data.user));
  }).catch((error) => {
    setErrorMessage(error.data?.message);
    toast.error(error.data?.message);
    console.log('erreur: ',error);
    
  })
};

  return (
    <div className=" px-6 mt-8 flex gap-10 w-full">
      <div className=" xl:w-[80%] bg-white pb-4 border-grys/20 shadow rounded-lg  xl:m-auto w-full">
        <div className="border-b border-grys/20 w-full py-3 mb-4">
          <p className="text-center font-bold text-vr text-xl">
            Informations personnels
          </p>
        </div>
        <div className=" max-sm:block hidden mb-6 w-full flex-col justify-center">
          <div className=" w-[250px] m-auto  flex justify-center relative">
            {!user?.profile ? (
              <img
                src={imagePreview || imageProfile}
                alt="Profile"
                className={`size-48 rounded-full border-2  border-orange`}
              />
            ) : (
              <img
                src={imagePreview || `${apiUrlImg}/${user?.profile}`}
                alt="Profile"
                className={`size-48 rounded-full border-2  border-orange`}
              />
            )}
            <label htmlFor="editProfile">
              <BsFillCameraFill
                className=" absolute hover:text-rg cursor-pointer text-3xl bg-blc border-2 border-orange p-1 
              rounded-full  text-vr top-32 right-7"
              />
            </label>
          </div>

          <div className="my-4">
            <p className={`text-center font-bold text-bl text-2xl`}>
              {user?.name}
            </p>
            <p className={`text-center text-bl text-md`}>{user?.email}</p>
          </div>
        </div>
        <input
          type="file"
          id="editProfile"
          name="editProfile"
          className="hidden"
          onChange={handleFileChange}
        />

        {errorMessage && (
          <div className="bg-red-100 flex gap-3 px-5 py-2 bg-orange/15 justify-center items-center mx-10 rounded-xl">
            <div className="border-r-2 h-14 flex items-center pe-3 border-rg">
              <BiError className="text-4xl text-rg" />
            </div>
            <p className="text-rg text-start text-md font-bold italic ">
              {errorMessage}
            </p>
          </div>
        )}

        <div className="w-full flex-col justify-center my-4 px-10">
          <div className="md:flex flex-wrap justify-between">
            {profileInputs.map((input, index) => (
              <div key={index} className="w-full md:w-[48%] mb-4">
                <MonInput
                  label={input.label}
                  type={input.type}
                  id={input.id}
                  onChange={handleInputChange}
                  paddingB="h-10 bg-white text-vr rounded-lg border-grys/40 "
                  options={input.id === "pays" ? countryOptions : input.options}
                  placeholder={
                    typeof input.placeholder === "function"
                      ? input.placeholder(user)
                      : input.placeholder
                  }
                  position={"flex-col"}
                  value={formState[input.id]}
                  labelStyle="text-lg ms-1 text-gray-800 w-full mb-1"
                />
              </div>
            ))}
          </div>

          {user.statut === "bakeliste" && (
            <div className="relative mt-4">
              <label
                htmlFor="bio"
                className="text-sm font-semibold ms-1 text-gray-800 w-full"
              >
                Biographie
              </label>
              <BiEdit className="absolute text-2xl top-12 left-2" />
              <textarea
                className="border ps-10 mt-1 py-4 border-grys/40 rounded-lg resize-none w-full h-28 bg-white outline-none text-grys"
                name="bio"
                id="bio"
                placeholder="Ecrire ma biographie"
              ></textarea>
            </div>
          )}
          <button
            className={`py-2 mt-4 w-full ${
              user.picture || user.avatar_url ? "hidden" : "block"
            } hover:scale-[98%] duration-500  text-blc bg-vr rounded-lg font-bold px-4`}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <BeatLoader color="#ffffff" size={8} />
            ) : (
              "Sauvegarder"
            )}
          </button>
        </div>
      </div>

      {/** ================================ Espace entre les Sections ========================== */}

      <div className=" max-sm:hidden w-[500px]  bg-blanc  shadow rounded-lg h-auto ">
        <div className="border-b-2 border-grys/20 w-full py-3 mb-8">
          <p className="text-center font-bold text-vr text-xl">
            Photo de profil
          </p>
        </div>
        <div className=" w-full flex-col justify-center">
          <div className=" w-[250px] m-auto  flex justify-center relative">
            {!user.profile ? (
              <img
                src={imagePreview || imageProfile}
                alt="Profile"
                className={`size-48 rounded-full border-2  border-orange`}
              />
            ) : (
              <img
                src={imagePreview || `${apiUrlImg}/${user.profile}`}
                alt="Profile"
                className={`size-48 rounded-full border-2  border-orange`}
              />
            )}
            <label htmlFor="editProfile">
              <BsFillCameraFill
                className=" absolute hover:text-rg cursor-pointer text-3xl bg-blc border-2 border-orange p-1 
              rounded-full  text-vr top-32 right-7"
              />
            </label>
          </div>

          <div className="my-4">
            <p className={`text-center font-bold text-bl text-2xl`}>
              {user.name}
            </p>
            <p className={`text-center text-bl text-md`}>{user.email}</p>
          </div>
        </div>
        <input
          type="file"
          id="editProfile"
          name="editProfile"
          className="hidden"
          onChange={handleFileChange}
        />
        {user.statut === "bakeliste" && (
          <label
            htmlFor="editProfile"
            className="flex flex-col w-[180px] hover:bg-grys/20 mt-8 text-bl border border-orange/40 py-2  
              rounded-lg items-center justify-center m-auto "
          >
            <BiImage className=" cursor-pointer  text-3xl top-32 right-7" />
            choisir une photo
          </label>
        )}
      </div>
    </div>
  );
};

export default ParameterClientPage;
