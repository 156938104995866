import React from 'react'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { LuPencilLine } from 'react-icons/lu'
import { menuDetailProjet } from '../../Utils/UtilsSidebar'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints'
import { formatDate } from '../../Utils/FormatDate'
import RenderWysiwyg from 'render-wysiwyg'
import { useFetchProjectsQuery } from '../../../services/auth-services/AuthService'

function ProjectsDetail({pourcentage}) {
    const { slug } = useParams();
    const { data: projets, isLoading:loadingProject } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);
    const project = projets && projets?.find((proj) => proj.slug === slug)
    // console.log(project);

    return (
    <div className='flex justify-center'>
        <div className='bg-white w-[95%] rounded p-5'>
            <div className='header flex justify-between pb-3'>
                <div className='flex gap-2 items-center'>
                    <NavLink to="/client-dashboard/dashboard">
                        <FaArrowLeftLong />
                    </NavLink>
                    <h1 className='font-bold'>{project? project?.titre: "Projet"}</h1>
                </div>
                <div className=''>
                    <LuPencilLine />
                </div>
            </div>
            <div className='content'>
                <div className='flex justify-between'>
                    <div className='w-[50%]'>
                        {/* <h3>Domaine: <span className='font-bold'>{domaine? domaine : "Marketing"}</span></h3> */}
                        <h3 className='text-sm'>Date de création: <span className='font-bold'>{project? formatDate(project?.created_at) : "12/06/2024"}</span></h3>
                        <h3 className='text-sm'>Objectif: <span className='font-bold'>{project ? project?.objectif : "Objectifs"}</span></h3>
                        <h3 className='text-sm inline-flex gap-1'>Description: <span className='font-bold'><RenderWysiwyg content={project && project?.description} /></span></h3>
                    </div>
                    <div className='w-[40%]'>
                        <h3 className='text-xs mb-3'>Progression du projet</h3>
                        <div className='w-full'>
                            <div className="w-full bg-gray-200 rounded-full h-[8px]">
                                <div className="bg-green-500 h-full rounded-full" style={{ width: `${pourcentage ? pourcentage : 0}%` }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-b py-3'>
                    <div className='flex gap-3 items-center w-2/4'>
                        {menuDetailProjet.map((elem, index) => (
                            <NavLink to={elem.path} className="flex justify-center p-3 bg-gray-200 w-2/6 text-xs rounded-lg" key={index}>{elem.text}</NavLink>
                        ))}
                    </div>
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
        </div>
    </div>
  )
}

export default ProjectsDetail