import React, { useState } from 'react';
import InputWysiwyg from '../../Utils/InputWysiwyg';
import ImageContainerDisplay from './livraisonsComponents/ImageContainerDisplay';
import LivraisonsDetails from './livraisonsComponents/LivraisonsDetails';
import { LuListChecks } from "react-icons/lu";
import MonBouton from '../../Utils/design-system/buttons/MonBouton';
import { IoList, IoRemoveOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { CLIENT_DASHBOARDS, LIVRAISONSDETAIL, PROJECTS } from '../../../Routes/navigation/navigationPaths';
import { useModal } from '../../../context/ModalContext';
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints';
import { toast } from 'react-toastify';
import { CiCirclePlus } from "react-icons/ci";
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useGetDataQuery, usePostDataMutation } from '../../../services/auth-services/AuthService';

const Livraisons = ({task, profileLetters, name}) => {
  const [text, setText] = useState("");
  const {closeModal} = useModal();
  const [images, setImages] = useState([]);
  const [captures, setCaptures] = useState([]);
  const [openInput, setOpenInput] = useState(false);
  const { data: livraisons, isLoading: loadingLivraison, refetch } = useGetDataQuery({endpoint: ProjectsEndpoints.getLivraisons, projectId: task?.id});
  const navigate = useNavigate();
  const [postData, { isLoading } ] = usePostDataMutation();
  const user = useSelector(selectData)
  
  const handleImageUpload = (files) => {
    const uploadedImages = Array.from(files).map(file => URL.createObjectURL(file));
    const uploadImageSending = Array.from(files).map(file => file)
    setImages(prevImages => [...prevImages, ...uploadedImages]);
    setCaptures(prevImages => [...prevImages, ...uploadImageSending])
  };

  const handlePosteLivrable = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('bakeliste_id', user?.id)
    formData.append('description', text);
    captures.forEach((capture, index) => {
      formData.append(`captures[${index}]`, capture);
    });
    
    postData({ endpoint: ProjectsEndpoints.ajoutLivraison, elementID: task?.id, formData })
    .unwrap()
    .then((result) => {
      toast.success(result.message);
      refetch();
      setText("");
      setCaptures([]);
      setImages([]);
      setOpenInput(false);
    }).catch((error) => {
      console.log(error);
      toast.error(error);
    });
  }

  const switchDetailDelivery = () => {
    navigate(`/${CLIENT_DASHBOARDS}${PROJECTS}/${LIVRAISONSDETAIL}/${task?.id}`)
    closeModal()
  }

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <div className="">
      <div className="flex justify-between items-center max-md:grid gap-3 max-md:ps-1">
        <div className="flex items-center mt-4 gap-4">
          <div className="w-full">
            <div className="flex gap-2 items-center">
              <h3 className="text-noir_300 text-xl font-bold">Livraisons</h3>
              <span className="text-xl font-normal text-grys">
                ({livraisons?.livraisons?.length ? livraisons?.livraisons?.length : 0})
              </span>
            </div>
        </div>
          {user?.statut !== "coach" && (
            <MonBouton
              type={"button"}
              couleurFond={"bg-orange w-auto text-nowrap text-white"}
              bordure={
                "rounded-full flex justify-center items-center text-xs py-[9px] hover:scale-[97%] transition-all duration-500 px-3"
              }
              icone={
                openInput ? (
                  <IoRemoveOutline className="text-gray-400" size={"14px"} />
                ) : (
                  <CiCirclePlus className="" size={"15px"} />
                )
              }
              action={() => setOpenInput(!openInput)}
              children={"Ajouter une livraison"}
            />
          )}
        </div>
      </div>
      <ImageContainerDisplay
        images={images}
        onRemoveImage={handleRemoveImage}
        height={"120px"}
        width={"300px"}
        margin={"10px"}
      />
      {openInput && (
        <InputWysiwyg
          text={text}
          setText={setText}
          onImageUpload={handleImageUpload}
          placeholder="Ajouter un livrable"
          height="150px"
          livrable={true}
          width="w-full"
          submit={handlePosteLivrable}
          isLoading={isLoading}
        />
      )}
      <LivraisonsDetails
        name={name}
        profileLetters={profileLetters}
        refecthData={refetch}
        livraisons={livraisons?.livraisons}
        switched={() => switchDetailDelivery()}
        isLoading={loadingLivraison}
        task={task}
      />
    </div>
  );
};

export default Livraisons;