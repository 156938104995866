import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { selectToken } from "../../redux/features/userSliceWithTokenValidation";
import { apiUrl } from "../../Routes/api/global_vars";

export const API_TEMPLATE = createApi({
    reducerPath: "template",
    baseQuery: fetchBaseQuery({
      baseUrl: apiUrl,
      prepareHeaders: (headers, { getState }) => {
        const token = selectToken(getState());
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        // headers.set("Content-Type", "application/json");
        return headers;
      },
    }),
    endpoints: (builder) => ({
        getTemplates: builder.query({
            query: (type) => `${type || ""}`,
        }),
    })
});
  
  export const {
    useGetTemplatesQuery,
  } = API_TEMPLATE;
  
  export default API_TEMPLATE