import React from "react";
import "./App.css";
import { AppProvider } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { PrimeReactProvider } from "primereact/api";
import "./flags.css";
import 'react-tooltip/dist/react-tooltip.css'
import Roots from "./Routes/Roots";
import { ModalProvider } from "./context/ModalContext";

function App() {
  return (
      <ModalProvider>
        <AppProvider>
          <PrimeReactProvider>
            <div className="App">
              <ToastContainer />
              <Toaster position="top-right" />
              <Roots />
            </div>
          </PrimeReactProvider>
      </AppProvider>
    </ModalProvider>
  );
}

export default App;
