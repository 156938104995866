import { Navigate} from "react-router-dom";
import { ProjectsEndpoints } from "../../../Routes/api/Endpoints";
import { selectData } from "../../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useFetchProjectsQuery } from "../../../services/auth-services/AuthService";

export const ProjectsRedirect = () => {
  const { data: projets, error, isLoading } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);;
  const projetUser = useSelector(selectData)
  const filteredProjects = projets?.filter((proj) => proj.assigned_to_id === projetUser?.id || proj.participants_ids?.includes(projetUser?.id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading projects</div>;
  }

  if (filteredProjects && filteredProjects.length > 0) {
    return <Navigate to={`${filteredProjects[0].slug}`} />;
  }

  return <div>No projects found</div>;
};