import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri";
import { TiFolderOpen } from "react-icons/ti";
import { NavLink } from "react-router-dom";
import { CLIENT_DASHBOARDS, PROJECTS } from "../../Routes/navigation/navigationPaths";
import { ProjectsEndpoints } from "../../Routes/api/Endpoints";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useFetchProjectsQuery } from "../../services/auth-services/AuthService";

function SidebarItem({ icon, text, path, dropdown }) {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [isIconExpanded, setIsIconExpanded] = useState(false);
  const { data: projets, refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);
  const projetUser = useSelector(selectData)
  const filteredProjects = projets?.filter((proj) => proj.assigned_to_id === projetUser?.id);
  const projectsBakeliste = projets?.filter((proj) => proj.participants_ids?.includes(projetUser?.id))
  const toggleDropdown = () => {
    setIsContentVisible(!isContentVisible);
    setIsIconExpanded(!isIconExpanded);
  };

  return (
    <div>
      <NavLink to={path} 
        className={`flex ${dropdown ? "px-0" : "px-3"} items-center max-xl:space-x-0 text-blueclear max-xl:px-0 max-xl:ps-2 py-1 containerItems`}
        onClick={dropdown ? toggleDropdown : undefined}
      >
        
        <span className={`font-bold inline-flex text-lg w-1/4 items-center justify-center`}>
          {dropdown && (
          <span>
            {isIconExpanded ? <RiArrowDropDownFill size={18} /> : <RiArrowDropRightFill size={18} />}
          </span>
        )}
          {icon}
        </span>
        <span className="text-xs p-1 text-nowrap font-semibold text-start w-3/4 font-inter max-xl:text-xs">
            {text}
        </span>
      </NavLink>
      {dropdown && isContentVisible && (
        <div className="text-blueclear pt-2">
          {projetUser?.statut ==="coach"? filteredProjects?.map((proj, i) => (
            <NavLink className="flex items-center max-2xl:ps-14 ps-[4.5rem] space-x-2 gap-2 pt-2 cursor-pointer containerItem max-xl:px-0 max-xl:ps-[4rem] max-xl:gap-1" key={i} to={`/${CLIENT_DASHBOARDS}${PROJECTS}/${proj.slug}`}>
              <span className=" text-xs font-inter font-semibold">{proj.titre}</span>
            </NavLink>
          )): projectsBakeliste?.map((proj, i) => (
            <NavLink className="flex items-center px-8 space-x-2 gap-2 pt-2 cursor-pointer containerItem" key={i} to={`/${CLIENT_DASHBOARDS}${PROJECTS}/${proj.slug}`}>
              <span className="text-xs font-inter font-semibold">{proj.titre}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

export default SidebarItem;