import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button"; 
import Select from 'react-select';
import { toast } from "react-hot-toast";

const MonInput = ({
  label,
  type,
  id,
  value,
  onChange,
  options,
  placeholder,
  paddingB,
  MessageError,
  labelStyle,
  labelSelect,
  endPoint,
  jsonKey,
  name,
  required,
  padding,
  suffix,
  prefix,
  checked,
  marginB,
  position,
}) => {
  const [visible, setVisible] = useState(false);
  const [newItemName, setNewItemName] = useState("");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent !important',
      height: '28px !important',
      outilne: 'none',
      borderRadius: '10px',
      padding: '0px !important',
      '&:focus': {
        outline: 'none !important',
        boxShadow: 'none !important',
      }
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const formatOptionLabel = ({ label, flag }) => (
    <div className="flex items-center !bg-transparent">
      <img src={flag} alt="" className="inline-block mr-2 w-5 h-4" />
      {label}
    </div>
  );

  /* ====== Note ===============
    Cette Fonction d'ajout de Nouvelles Types dans les differents 
    Selects elle fonctionne ! Il suffit de decommenter le Bouton plus (+) dans le return
  =================================*/

  const handleAddNewItemClick = async () => {
    if (newItemName.trim() !== "") {
      try {
        const response = await fetch(endPoint, { 
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ [jsonKey]: newItemName }),
        });
        const data = await response.json();
        if (response.ok) {
          toast.success(data.message);
        } else {
          throw new Error(data.message ? Object.values(data.message).join(', ') : "Erreur lors de l'ajout");
        }
      } catch (error) {
        toast.error(error.message || "Erreur lors de l'ajout");
      }
      setNewItemName("");
      setVisible(false);
    }
  };


  if (type === "select" && options) {
    return (
      <div className="mt-4">
        {/* Modal pour ajouter un nouvel élément */}
        <Dialog
          header="Créer"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <div className="flex flex-col gap-2">
            <label htmlFor="">{labelSelect}</label>
            <InputText
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              className="border my-3 w-full h-[35px]"
            />
            <small id="">{MessageError}</small>
            <div className="button">
              <Button
                label="Créer"
                className="grid-flow-col items-center justify-center font-semibold outline-none transition duration-75 focus-visible:ring-2 rounded-lg gap-1.5 px-3 py-2 text-sm inline-grid shadow-xl bg-[#4F46FE] text-white hover:bg-custom-500 focus-visible:ring-custom-500/50 dark:bg-custom-500 dark:hover:bg-custom-400 dark:focus-visible:ring-custom-400/50 border border-gray"
                onClick={handleAddNewItemClick}
              />
              <button
                className="mx-3 grid-flow-col items-center justify-center font-semibold outline-none transition duration-75 focus-visible:ring-2 rounded-lg gap-1.5 px-3 py-2 text-sm inline-grid shadow-xl bg-white text-gray-950 hover:bg-gray-50 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 ring-1 ring-gray-950/10 dark:ring-white/20 border border-gray"
                type="button"
                onClick={() => setVisible(false)}
              >
                <span className="text-gray-950">Annuler</span>
              </button>
            </div>
          </div>
        </Dialog>

        <label
          htmlFor={id}
          className={`${labelStyle} text-blck font-sans font-medium text-lg`}
        >
          {label}
        </label>
        <div className="flex items-center">
          <select
            id={id}
            className={`custom-select text-black text-opacity-90 ${paddingB}  bg-grise border-gr focus:border-vr px-2 rounded-xl  py-0 border focus:outline-none w-full `}
            aria-label={label}
            value={value}
            onChange={onChange}
          >
            {/* Option vide pour le placeholder */}
            <option value="" disabled={!value}>
              {placeholder}
            </option>
            {/* Afficher les autres options */}
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  } else {
    // Autres types d'inputs
    return (
      <div className={`mt-4 flex flex-col justify-center items-center ${marginB}`}>
        <div className="flex w-full justify-center bg-vrm-0">
          {prefix}
          {id==="pays" ? <Select
            id={id}
            value={options.find((option) => option.value === value)}
            onChange={(selectedOption) => onChange({ target: { id, value: selectedOption.label } })}
            options={options}
            placeholder={"Selectionner un pays"}
            className="w-full text-blck text-opacity-90 text-xs h-6"
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
          /> : <div className={`flex items-center justify-center w-full ${position}`}>
              {label && <label htmlFor={id} className={`${labelStyle} text-noirel font-inter font-medium text-sm`}>
              {label}{required && <span className="text-red">*</span>}
            </label>}
            <input
              type={type}
              id={id}
              className={`text-blck text-opacity-90 font-sans font-medium text-md ${paddingB} border-grys focus:border-vr px-2 ${padding} border focus:border-1  focus:outline-none w-full bg-grise`}
              aria-label={label}
              value={value}
              onChange={onChange}
              name={name}
              placeholder={placeholder}
              checked={checked}
            />
          </div>}
          {suffix}
        </div>
        <div className="flex mt-1 justify-center w-full ">
          <small className="text-rg flex items-center text-center font-bold text-[14px] text-wrap">
            {MessageError}
          </small>
        </div>
      </div>
    );
  }
};


export default MonInput;


