import React from "react";
import LoginForm from "./LoginForm";
import { BiLoaderCircle } from "react-icons/bi";

function LoginComponent() {
  return (
    <div className="bg-white px-12 rounded-3xl py-7 max-lg:w-full max-lg:px-16 max-sm:px-8 max-xl:px-9">
      <div>
        <div className="flex items-center justify-center pt-4 px-2">
          <a className={`flex items-center gap-4`} href="/">
            <BiLoaderCircle
              className={`w-11 h-12 fill-current max-md:w-8 max-md:h-10 text-orange`}
            />
            <p className="text-4xl font-[500] text-nowrap text-vr">
              Bakeli Work
            </p>
          </a>
        </div>
        <div className="mt-10">
          <p className="text-dsn px-4 text-center max-xl:px-0 text-3xl font-bold">
            Connectez - Vous
          </p>
        </div>
      </div>
      <LoginForm />
    </div>
  );
}

export default LoginComponent;
