import React from 'react';
import CardprojectComponent from './CardprojectComponent';
import { useLocation } from 'react-router-dom';
import SkeletonLoader from '../../Utils/Skeleton';
import { ProjectsEndpoints } from '../../../Routes/api/Endpoints';
import { selectData } from '../../../redux/features/userSliceWithTokenValidation';
import { useSelector } from 'react-redux';
import { useFetchProjectsQuery } from '../../../services/auth-services/AuthService';

function CardProject() {
  const { data: projets, error, isLoading, refetch } = useFetchProjectsQuery(ProjectsEndpoints.getProjects);;
  const projetUser = useSelector(selectData)
  const location = useLocation();
  const userProjects = projets?.filter((proj) => proj.user_id == projetUser?.porteur_projet?.id)

  return (
    <div className={`grid grid-cols-3 gap-4 pt-7 text-black`}>
      {isLoading ? <SkeletonLoader cardProject={true} /> : userProjects && userProjects.length > 0 ? userProjects?.map((elem, index) => (
        <CardprojectComponent key={index}
          image={elem?.image}
          budget={elem?.budget} 
          titre={elem?.titre}
          etat={elem?.etat}
          objectif={elem?.objectif}
          path={`${location.pathname}/${elem?.slug}`}
        />
      )): <h1 className="text-xl font-semibold text-center">Aucun projet disponible pour le moment</h1>}
    </div>
  )
}

export default CardProject