import React from "react";
import { useAppContext } from "../../context/AppContext";
import MonBouton from "../Utils/design-system/buttons/MonBouton";
import SearchModal from "../composPageAccueil/SearchFuction/SearchModal";
import { useNavigate } from "react-router-dom";
import { CardesTemplate } from "../composPageAccueil/CardesTemplate";
import Logo from "../../assets/LogoHubForge.png";
import DefaltProfile from "../../assets/userLogo.jpg";
import { selectData } from "../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { useGetTemplatesQuery } from "../../services/templates_services/TemplateService";

export const MesTemplates = () => {
  const { apiUrlImg } = useAppContext();
  const { data: templates } = useGetTemplatesQuery();
  const navigate = useNavigate();
  const user = useSelector(selectData);

  const getCreator = (template) => {
    if (template.user_role === "bakeliste") {
      return {
        creator: template.user_name,
        profile:
          template.user_profile === null
            ? DefaltProfile
            : `${apiUrlImg}/${template.user_profile}`,
      };
    } else {
      return { creator: "Bakeli Work", profile: Logo };
    }
  };

  const mesTemplates = templates?.filter((t) => t.user_name === user.name);

  return (
    <div className={`w-full mt-28 mb-16 }`}>
      <SearchModal />

      <div
        className={` bg-gray-100 w-[82%] fixed top-10 right-6 z-[12] max-md:w-[90%] max-lg:w-[80%] max-lg:right-8 max-md:top-12 flex justify-center max-sm:right-4 max-sm:w-[95%]`}
      >
        <MonBouton
          textColor="mt-14 w-[50%] max-sm:w-[90%] hover:scale-[97%] max-md:text-[16px] transition-all duration-500 max-sm:px-24 rounded-full py-2 text-xl"
          couleurFond="bg-vr text-blc"
          action={() => navigate("addForm")}
        >
          <p className="w-full"> Ajoute ton template +</p>
        </MonBouton>
      </div>
      <div className={` max-lg:w-[100%] flex gap-7 justify-center flex-wrap`}>
        {mesTemplates?.map((temp) => {
          const { creator, profile } = getCreator(temp);
          return (
            <CardesTemplate
              key={temp.id}
              HandlePreview={temp.aperçu}
              imageTemplate={`${apiUrlImg}/${temp.image}`}
              handlePriceTo={temp.aperçu}
              titreCrdTemplate={temp.titre}
              DesctiptionTemplate={temp.description}
              prixTemplate={temp.prix === null ? "Free" : temp.prix}
              telechargeLink={`/telecharge/${temp.slug}`}
              userName={creator}
              userProfile={profile}
            />
          );
        })}
      </div>
    </div>
  );
};
