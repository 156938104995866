export const profileInputs = [
  {
    label: "Prénom & Nom",
    type: "text",
    id: "name",
    placeholder: (user) => user.name,
  },
  {
    label: "Téléphone",
    type: "text",
    id: "number",
    placeholder: (user) => user.number,
  },
  {
    label: "Mot de passe",
    type: "password",
    id: "password",
    placeholder: "Votre nouveau mot de passe",
  },
  {
    label: "Confirmation",
    type: "password",
    id: "password_confirmation",
    placeholder: "Confirmez le mot de passe",
  },
  {
    label: "Pays de résidence",
    type: "text",
    id: "pays",
    placeholder: "Pays de résidence",
  }
];
